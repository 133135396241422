.loading-box {
	padding: 40px;
	max-width: 450px;
	margin: 20px auto;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	.lb {
		column-gap: 40px !important;
		display: grid;
		grid-template-columns: auto 1fr;
	}

	h3 {
		font-weight: 700 !important;
		text-transform: uppercase;
	}
}
