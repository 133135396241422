/* General SCSS */

// Typography
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Notes for font weight - (300 - Light, 400 - Regular, 500 - Medium, 600 - Semibold, 700 - Bold, 800 - Extra Bold)
body {
	font-family: 'Inter', sans-serif !important;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: 'Inter', sans-serif !important;
	}

	h1 {
		font-weight: 900 !important;

		&.header {
			font-size: 24px !important;
		}
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		font-weight: 700 !important;
	}

	p,
	a,
	span,
	ul li,
	div,
	button,
	li {
		font-family: 'Inter', sans-serif !important;
		font-weight: 400 !important;
	}

	p.m {
		margin-bottom: 25px !important;
	}
}

// Containers, content boxes
.container {
	padding: 50px;

	&.c {
		text-align: center;
	}
}

.flex-box {
	display: flex;
	column-gap: 15px;

	&.m {
		margin-bottom: 15px;
	}

	&.sb {
		justify-content: space-between;
	}

	&.ctr {
		justify-content: center;
	}

	&.ctr-all {
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		align-content: center;
	}

	&.row {
		flex-direction: row;
	}

	&.col {
		flex-direction: column;

		&.rg {
			row-gap: 25px;
		}
	}

	&.bcg {
		column-gap: 30px;
	}
}

.grid-box {
	display: grid;
	column-gap: 30px;
	position: relative;

	&.two {
		grid-template-columns: 1fr 1fr;
	}

	&.three {
		grid-template-columns: 1fr 1fr 1fr;
	}

	&.four {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	&.row {
		row-gap: 30px;
	}
}

// Web header
.icon-header {
	margin-bottom: 20px;

	img {
		width: 40px;
	}

	h2 {
		font-size: 26px;
	}
}

// Loading
.skeleton-loading {
	background-color: rgba(0, 0, 0, 0.11) !important;
}

// Form Styling
.form-field {
	margin: 10px 0 25px !important;
}

// Datagrid styling
.MuiDataGrid-menu .MuiPaper-root,
.MuiDataGrid-panel .MuiPaper-root {
	background-color: #fff !important;
	color: #000 !important;

	.MuiDivider-root {
		border-color: rgba(0, 0, 0, 0.11);
	}

	.MuiSvgIcon-root {
		color: #000 !important;
	}

	.MuiInputBase-root {
		color: #000 !important;

		.MuiNativeSelect-select option {
			background-color: #fff;
		}
	}
}

// App styling
.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

main {
	display: flex;
	flex: 1 1 auto;
	position: relative;
	overflow: hidden;

	.page-content-container {
		display: flex;
		flex: 1 1 100%;
		flex-direction: column;
		overflow: hidden;

		.page-content {
			padding: 50px;
		}
	}

	.bg-img {
		max-width: 300px;
		position: absolute;

		&.top {
			top: 0;
		}

		&.bottom {
			bottom: -20px;
			right: -20px;
		}
	}
}
