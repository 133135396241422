.tum-loading-row-container {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	padding: 20px;

	.row-text {
		display: flex;
		flex-direction: column;
		row-gap: 2.5px;
	}

	.row-icon {
		display: flex;
		align-items: center;
	}
}
