.videos-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	h2 {
		font-size: 26px;
	}

	h3 {
		margin: 30px 0;
		font-size: 16px;
		color: #000;
	}

	.videos-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 50px;
		row-gap: 30px;

		.video {
			display: grid;
			grid-template-columns: 1fr 2fr;
			column-gap: 30px;
			align-items: center;

			.react-player__preview {
				box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
					rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
			}

			h4 {
				font-weight: 400 !important;
			}
		}
	}
}
