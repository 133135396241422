.dashboard-card-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.dashboard-card-header {
		padding: 20px 30px;
		display: flex;
		flex-direction: row;
		column-gap: 30px;
		align-items: center;

		h3 {
			font-size: 16px;
			font-weight: 700 !important;
			text-transform: uppercase;
		}

		img {
			width: 24px;
			height: 24px;
		}
	}

	.dashboard-card-content {
		padding: 18px;
		text-align: center;
		display: flex;
		flex-direction: column;
		flex: 1 1 100%;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		justify-content: center;
		min-height: 85px !important;

		.dashboard-card-content-flex {
			display: flex;
			justify-content: space-between;
			column-gap: 20px;
		}

		.dashboard-card-content-col {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			text-align: center;
			align-content: center;
			flex-wrap: wrap;

			svg {
				font-size: 30px;
				margin-bottom: 10px;
			}

			h2 {
				font-size: 30px;
			}
		}

		.dashboard-card-content-desc {
			display: flex;
			flex-direction: column;
			row-gap: 20px;

			.configure-sda-button {
				align-items: center;
			}
		}
	}

	.tum-li {
		grid-auto-rows: 1fr;
	}
}
