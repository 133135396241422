.resend-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.resend-header {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		text-align: center;

		.header-icon {
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				padding: 10px;
				background-color: #0075c9;

				svg {
					font-size: 40px;
					color: #fff;
				}
			}
		}
	}

	.resend-form-content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.form-section {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
		}
	}

	.resend-btn {
		text-align: center;
	}
}

// Loading
.resend-loading {
	padding: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	text-align: center;

	.resend-loader {
		max-width: max-content;
		margin: auto;
	}
}

.modal:has(.resend-loading) {
	overflow: hidden;
}
