.tn-form-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.tn-form-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 20px;
	}

	.tn-heading {
		display: grid !important;
		grid-template-columns: 0.5fr 1fr !important;

		p {
			padding: 10px 10px;
		}
	}

	.dropdown-box {
		display: grid !important;
		grid-template-columns: 1fr 1fr !important;
		column-gap: 15px;

		.dropdown {
			padding-top: 10px;
		}
	}

	.add-tn-button {
		button {
			padding: 5px 20px !important;
			background-color: #0075c9 !important;
			color: #fff !important;
			border-radius: 7px !important;
			margin: 0 !important;

			&[disabled] {
				background-color: #9eb5ea !important;
			}
		}
	}

	.add-new-range-btn {
		display: flex !important;
		justify-content: center;
		padding-top: 10px;

		button {
			padding: 5px 20px !important;
			background-color: #0075c9 !important;
			color: #fff !important;

			&[disabled] {
				background-color: #9eb5ea !important;
			}
		}
	}

	.invalid {
		color: red;
	}

	.valid {
		color: black;
	}
}
