.trunks-container {
  .trunk-ips {
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;

    .MuiChip-root {
      font-size: 14px;
    }
  }

  .trunks-content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    .trunk-region-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 30px;

      .trunk-region {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .trunk-region-header-container {
          display: flex;
          justify-content: space-between;

          .trunk-region-header {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            align-items: center;

            h2 {
              font-size: 20px;
            }
          }
        }

        .trunk-region-ip-container {
          display: grid;
          grid-template-columns: auto auto auto auto;
          column-gap: 10px;
        }

        .trunk-region-delete {
          display: flex;
          justify-content: center;
          position: relative;

          svg.MuiSvgIcon-root {
            color: #db3d47 !important;
          }
        }
      }
    }

    .MuiDataGrid-booleanCell[data-value='true'] {
      color: #26b087 !important;
    }

    .MuiDataGrid-booleanCell[data-value='false'] {
      color: #db3d47 !important;
    }
  }
}

// Empty display
.empty-trunk-container {
  display: flex;
  justify-content: center;

  .empty-trunk-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    justify-content: center;
    align-items: center;

    img {
      max-width: 150px;
    }

    h1 {
      font-size: 20px;
    }
  }
}
