.header-container {
  text-align: center;

  .header-content {
    padding: 20px;
  }
}

.header-logo {
  width: auto;
  max-height: 30px;
  display: flex;
  align-self: center;

  img {
    width: auto;
    max-height: 30px;
    cursor: pointer;
  }
}

.header-user {
  text-align: left;

  .icon {
    font-size: 20px;
    color: #000 !important;
  }

  p {
    font-size: 14px;
  }
}
