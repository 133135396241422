.landing-container {
	box-shadow: 0px 8px 10px #00000033;
	width: 100%;

	.landing-content {
		padding: 40px;

		.landing-text {
			display: flex;
			flex-direction: column;
			row-gap: 30px;

			h1 {
				font-size: 24px;
			}
		}

		.landing-button-image {
			display: grid;
			grid-template-columns: 2fr 1fr;
			column-gap: 30px;

			button {
				padding: 8px 30px;
				margin-bottom: 30px;
			}

			.landing-image img {
				max-width: 400px;
			}

			.faq-content {
				display: flex;
				flex-direction: column;
				row-gap: 20px;

				.MuiPaper-root {
					border-radius: 0;
					box-shadow: none;

					&.Mui-expanded {
						margin: 0 !important;
					}
				}

				.MuiButtonBase-root.MuiAccordionSummary-root.faq-title {
					background: rgba(224, 243, 255, 0.5) !important;
				}

				.faq-title .MuiAccordionSummary-content {
					font-weight: 600 !important;
					color: #000 !important;
				}

				.MuiAccordionDetails-root {
					padding: 15px;
				}

				.documentation-answer-container {
					display: flex;
					flex-direction: column;
					row-gap: 15px;

					h1,
					h2,
					h3,
					h4,
					h5,
					h6,
					p,
					span,
					button,
					b,
					ol li,
					ul li {
						color: #000 !important;
						font-size: 16px !important;
					}

					b {
						font-weight: 600 !important;
					}
				}
			}
		}
	}
}

// If this is active
.page-content:has(div.landing-container) {
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: #e0f3ff;
}
