.view-order-notes-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.view-order-notes-header {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		text-align: center;

		.header-icons {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.icon {
				padding: 10px;

				svg {
					font-size: 40px;
					color: #fff;
				}
			}
		}

		.header-text {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			h2 {
				font-size: 20px;
			}
		}
	}
}
