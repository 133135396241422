.range-textboxes {
	display: flex !important;
	flex-direction: row;
	column-gap: 20px;
	padding: 15px 20px;
	justify-content: space-between;
}

.add-new-range-btn {
	display: flex !important;
	justify-content: center;
	padding-top: 10px;

	button {
		padding: 5px 20px !important;
		background-color: #0075c9 !important;
		color: #fff !important;

		&[disabled] {
			background-color: #9eb5ea !important;
		}
	}
}
