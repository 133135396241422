.ts-form-content {
	margin: 0 auto;
	width: 600px;
	overflow: hidden;
}

.ts-form-input {
	width: 90%;
	margin: 5px 0 !important;
}

.ts-form-input-select {
	width: 90%;
	margin: 5px 0 !important;
	font-size: medium;
}

.ts-form-input .MuiInputBase-formControl {
	border-radius: 0 !important;
}

.ts-form-input .Mui-focused {
	color: #0c9ed9 !important;
}

.ts-form-input input {
	border-radius: 0 !important;
}

.ts-form-button {
	display: flex;
	justify-content: center;
}

.ts-form-label {
	margin-bottom: 10px;
}
