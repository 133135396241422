.tum-container {
	display: flex;
	align-items: center;
	justify-content: center;

	.tum-loading-container {
		// Flexbox
		// @include flex-box-col;
		max-width: 85%;

		.tum-login-content {
			// Flexbox
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 0 20px;

			.icon svg {
				font-size: 60px;
			}

			.desc {
				max-width: 80%;
			}
		}
	}
}
