@import '../../../../../../../styles/colours.scss';

.tum-form-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	padding: 20px;

	.tum-form-header {
		display: flex;
		flex-direction: column;
		row-gap: 10px;

		.form-icon {
			display: flex;
			align-items: center;
			justify-content: center;

			.icon {
				padding: 10px;
				background-color: var(--primary);

				svg {
					font-size: 40px;
					color: var(--wht);
				}
			}
		}

		.form-text {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			text-align: center;

			h2 {
				font-size: 20px;
			}
		}
	}

	.tum-form-user-info {
		display: flex;
		column-gap: 15px;
		padding: 15px;
		align-items: center;
		background-color: var(--secondary);

		.avatar {
			border-radius: 0;
		}

		.user-info {
			p {
				font-size: 14px !important;
			}
		}
	}

	.tum-form-content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
	}

	.tum-form-btn {
		text-align: center;
		padding: 15px 0 5px;
	}
}
