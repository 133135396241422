.settings-container {
	section {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.s-header {
			display: flex;
			justify-content: space-between;

			.s-buttons {
				display: flex;
				align-items: center;
			}
		}

		.s-form-section {
			display: grid;
			grid-template-columns: 1fr 2fr;
			column-gap: 100px;

			.s-form-field {
				display: flex;
				flex-direction: column;
				row-gap: 20px;
			}
		}

		.s-text {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			h2 {
				font-size: 20px;
			}

			p {
				color: #808080;
			}

			h4 {
				font-size: 18px;
			}

			a {
				text-decoration: none;
				color: var(--primary);
			}
		}

		.s-table {
			.s-row {
				display: grid;
				grid-template-columns: 1fr 2.5fr 72px;
				column-gap: 20px;

				&.head {
					background: #e0f3ff;

					h5 {
						font-size: 16px;
						font-weight: 500 !important;
					}

					button {
						svg {
							color: var(--err);
						}

						&.Mui-disabled {
							cursor: not-allowed;

							svg {
								opacity: 0.5;
							}
						}
					}
				}

				.s-cell {
					padding: 12px 16px;
					display: flex;
					align-items: center;
					column-gap: 10px;

					&.r svg {
						color: var(--primary);
					}
				}
			}
		}

		.s-btn {
			button.del {
				background: var(--err);

				&:hover {
					background: var(--err);
					opacity: 0.8;
				}
			}
		}
	}
}

button.cancel {
	background: var(--err);

	&:hover {
		background: var(--err);
		opacity: 0.8;
	}
}
