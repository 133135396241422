.error-page {
	margin: 20px auto;
	max-width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
}

.error-message {
	margin: 10px 10px !important;
	padding: 20px;
}

.error-page img {
	object-fit: cover;
	max-width: 600px;
}

.error-page h1 {
	font-weight: 800 !important;
	font-size: 40px;
	padding: 10px 10px;
}

.error-page h3 {
	font-weight: 400 !important;
	font-size: 30px;
	padding: 10px 10px;
}

.error-page h6 {
	font-weight: 400 !important;
	padding: 10px 10px;
}

/* Responsiveness */

@media only screen and (max-width: 1200px) {
	.error-page {
		max-width: 100%;
		width: 70%;
		display: block;
	}

	.error-dino img {
		object-fit: cover;
		max-width: 400px;
	}

	.error-message {
		padding: 30px 20px;
		background-color: #26b087;
		color: #121212;
		border-radius: 10px;
		font-size: 16px;
	}
}

@media only screen and (max-width: 600px) {
	.error-page {
		max-width: 100%;
		width: 70%;
		display: block;
	}

	.error-dino img {
		object-fit: cover;
		max-width: 200px;
	}
}
