.order-filter-container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.order-filter-content {
		display: grid;
		grid-template-columns: 200px 200px auto;
		column-gap: 20px;

		.order-filter {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			p {
				font-size: 14px;
				color: #000;
			}

			.MuiSelect-select,
			.MuiInputBase-input {
				padding: 10px;
			}
		}

		.add-filter {
			display: flex;
			align-items: flex-end;

			button {
				padding: 9px 20px;
			}
		}
	}

	.filters-applied {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.filter-chips {
			display: flex;
			column-gap: 15px;
		}

		h4 {
			color: #000;
		}

		.MuiChip-root {
			background-color: transparent !important;
		}
	}

	.filter-buttons {
		display: flex;
		column-gap: 20px;
		justify-content: flex-end;
	}
}
