.video-tooltips-container {
  padding: 40px;

  .video-dialog-img img {
    max-width: 250px;
  }

  .video-link {
    .li-icon-container {
      min-width: 20px !important;

      .li-icon {
        font-size: 0.7rem;
      }
    }

    &:hover {
      background-color: transparent !important;
    }

    .li-text {
      text-decoration: underline;
    }
  }
}

.close-icon {
  padding: 10px;
}

.no-video-container {
  svg {
    font-size: 6rem;
    margin: 20px 0;
  }
  h3 {
    color: #000;
    text-align: center;
  }
}
