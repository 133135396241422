.teams-overview-heading {
	display: flex;
	justify-content: space-between;
}

.teams-overview-img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: auto;
}
