.view-ddis-container {
	display: flex;
	flex-direction: column;
	row-gap: 15px;

	.filter-pagination {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;

		button {
			color: #0075c9;
		}

		.page-info {
			display: flex;
			flex-direction: row;
			column-gap: 10px;
			align-items: center;

			p {
				color: #0075c9;
			}

			hr {
				border-color: #0075c9;
			}
		}

		.pagination {
			display: flex;
			flex-direction: row;
			column-gap: 10px;
			align-items: center;

			.Mui-disabled {
				opacity: 0.5;
			}
		}
	}

	.view-ddis-filter-header {
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
		align-items: center;

		.view-ddis-filter-header-text {
			display: flex;
			flex-direction: column;

			h1 {
				color: #0075c9 !important;
				font-size: 18px;
				font-weight: 800;
			}

			p {
				color: #0075c9 !important;
				font-size: 14px;
			}
		}

		button {
			max-width: max-content;
			padding: 10px 20px !important;
			background-color: #0075c9 !important;
			color: #fff !important;
			border-radius: 0 !important;

			&[disabled] {
				background-color: #9eb5ea !important;
			}
		}
	}

	.ddi-options {
		display: flex;
		justify-content: space-between;

		img {
			width: 20px;
		}

		svg {
			width: 20px;
		}
	}
}

.view-ddis-filter-container {
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	padding: 20px 30px;

	h2 {
		font-size: 18px;
		color: #000;
		font-weight: 800;
	}

	h4 {
		font-size: 14px;
		font-weight: 600;
	}

	.view-ddis-filters {
		display: grid;
		column-gap: 15px;
		row-gap: 10px;
		grid-template-columns: 1fr 1fr 1fr;

		.view-ddis-filter {
			display: grid;
			grid-template-columns: 1fr 3fr;
			column-gap: 10px;
			align-items: center;

			p {
				font-size: 14px;
				color: #000;
			}
		}

		&.m {
			margin-bottom: 30px;
		}
	}

	.view-ddis-filter-buttons {
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
	}

	.filter-ddi-button {
		display: flex;
		align-items: center;

		button {
			max-width: max-content;
			padding: 5px 20px !important;
			background-color: #0075c9 !important;
			color: #fff !important;
			border-radius: 0 !important;

			&[disabled] {
				background-color: #9eb5ea !important;
			}
		}
	}

	.add-ddi-button {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		button {
			max-width: max-content;
			padding: 5px 20px !important;
			background-color: #0075c9 !important;
			color: #fff !important;
			border-radius: 0 !important;

			&[disabled] {
				background-color: #9eb5ea !important;
			}
		}
	}

	.country-img {
		max-height: 50px;
	}
}
