.documentation-container {
	padding: 20px;

	.documentation-content {
		display: grid;
		grid-template-columns: 1fr 2fr;
		column-gap: 30px;

		.documentation-box {
			padding: 30px;
			max-height: 300px;
			min-height: 300px;
			overflow-y: scroll;
			overflow-wrap: break-word;
			display: flex;

			.empty-documentation-display {
				display: flex;
				flex-direction: column;
				row-gap: 20px;
				align-items: center;
				justify-content: center;
				flex: auto;

				.empty-display {
					display: flex;
					flex-direction: column;
					align-items: center;

					h2 {
						text-transform: uppercase;
					}
				}
			}
		}

		.documentation-info-container {
			display: flex;
			flex-direction: column;
			row-gap: 20px;

			.two-image-layout {
				display: grid;
				grid-template-columns: 1fr 1fr;
				row-gap: 20px;

				img {
					max-width: 400px;
				}
			}
		}
	}
}
