.portrait {
	//fixed height + width
	max-height: auto;
	max-width: 300px;
	margin: 30px;
}

.landscape {
	//fixed height and width
	max-width: 800px;
	max-height: auto;
	margin: 30px;
}
