.breadcrumbs-container {
  padding: 10px 50px;

  .breadcrumbs-page-info {
    padding: 10px 0;

    h1.page-header {
      text-align: center;
      font-size: 24px;
      text-transform: uppercase;
    }
  }
}
