.landing-container {
	box-shadow: 0px 8px 10px #00000033;
	width: 100%;

	.app-reg-content {
		padding: 120px;

		.app-reg-text {
			display: flex;
			flex-direction: column;
			row-gap: 30px;
			justify-content: center;
			align-items: center;

			h2 {
				font-size: 24px;
			}
		}
		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
			width: 150px;
			height: 150px;
		}
	}
}
