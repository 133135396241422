.dashboard {
  .dashboard-content {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  .blur {
    filter: blur(15px);
  }

  .header-ln {
    margin-bottom: 30px;

    h2 {
      font-weight: 600 !important;
      width: 100% !important;
      font-size: 16px !important;
      text-transform: uppercase;
    }
  }

  .quick-actions {
    position: relative;
  }

  .alert {
    max-width: max-content !important;
    margin: 0 auto !important;
  }

  .orders-overview {
    margin-top: 30px;
  }
}

// Custom error box
.page-content:has(div.error-box) {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}
