.poc-container {
  .poc-grid-box {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 40px;
  }

  .poc-add-order-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: center;
  }

  h1 {
    font-size: 24px;
    text-transform: uppercase;
  }

  p.instances-p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .poc-form-content {
    margin: 0 auto;
    max-width: 800px;
    padding: 40px;
    border: 1px solid #d0d4d9;
    box-shadow: 0px 8px 10px #00000033;
    overflow: hidden;

    .poc-form {
      display: flex;
      flex-direction: column;
      row-gap: 25px;
    }
  }
}
