.confirm-order-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.confirm-order-header {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		text-align: center;

		.header-icons {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.icon {
				padding: 10px;

				svg {
					font-size: 40px;
					color: #fff;
				}
			}
		}

		.header-text {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			h2 {
				font-size: 20px;
			}
		}
	}

	.confirm-order-mb {
		padding: 5px 10px;
		background-color: #e9f7ff;
		text-align: center;
	}

	.confirm-order-btn {
		text-align: center;
	}
}
