.tum-display-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.tum-license-content {
		display: grid;
		grid-template-columns: 1fr 2fr;
		column-gap: 30px;
	}

	.tum-display-toolbar {
		display: flex;
		justify-content: space-between;

		.tum-filters {
			display: flex;
			column-gap: 10px;
			align-items: center;

			.tum-filter-button {
				display: flex;
				align-items: center;
			}
		}

		.tum-update-refresh {
			display: flex;
			align-items: center;
			column-gap: 20px;

			.tum-updates {
				display: flex;
				flex-direction: row;
				column-gap: 10px;
				align-items: center;
				padding: 5px 10px;
				border: 1px solid;
				cursor: pointer;
			}
		}
	}
}
