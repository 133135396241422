.address-management-container {
	display: flex;
	flex-direction: column;
	row-gap: 15px;

	.address-management-filter-header {
		display: flex;
		justify-content: space-between;
		column-gap: 30px;
		align-items: center;

		.address-management-filter-header-text {
			display: flex;
			flex-direction: column;

			h1 {
				color: #0075c9 !important;
				font-size: 18px;
				font-weight: 800;
			}

			p {
				color: #0075c9 !important;
				font-size: 14px;
			}
		}
	}

	.address-management-section-heading {
		display: flex;
		flex-direction: column;

		h2 {
			color: #0075c9 !important;
			font-size: 16px;
			font-weight: 800;
			margin-bottom: 10px;
		}
	}
}
