div.card-preloader {
	width: 4vmax;
	height: 4vmax;
	border-right: 4px solid #0075c9;
	border-radius: 100%;
	animation: spinRight 800ms linear infinite;

	&:before,
	&:after {
		content: '';
		width: 6vmax;
		height: 6vmax;
		display: block;
		position: absolute;
		top: calc(50% - 3vmax);
		left: calc(50% - 3vmax);
		border-left: 3px solid #0075c9;
		border-radius: 100%;
		animation: spinLeft 800ms linear infinite;
	}

	&:after {
		width: 4vmax;
		height: 4vmax;
		top: calc(50% - 2vmax);
		left: calc(50% - 2vmax);
		border: 0;
		border-right: 2px solid #0075c9;
		animation: none;
	}
}

@keyframes spinLeft {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(720deg);
	}
}

@keyframes spinRight {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
