.modal-form-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    // Header
    .modal-form-header {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .icon {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .main svg {
                font-size: 60px !important;
            }
        }

        .title {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            h1 {
                font-size: 20px !important;
            }

            p {
                color: #808080;
            }
        }
    }

    // Form Content
    .modal-form-content {
        display: flex;
        flex-direction: column;
        row-gap: 20px;

        .form-group {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            .form-label {
                .required {
                    color: #ff0000 !important;
                }
            }
        }
    }

    // Form button
    .modal-form-button {
        text-align: center;
    }
}