.form-content {
	margin: 0 auto;
	width: 600px;
	overflow: hidden;
}

.form h1 {
	font-size: 18px;
	text-align: center;
}

.form-input {
	width: 100%;
}

.form-input-select {
	width: 100%;
	margin: 10px 0 !important;
	font-size: medium;
}

.form-input .MuiInputBase-formControl {
	border-radius: 0 !important;
}

.form-input .Mui-focused {
	color: #0c9ed9 !important;
}

.form-input input {
	border-radius: 0 !important;
}

.form-button {
	margin: 5px 0;
	display: flex;
	justify-content: center;
}

.form-label {
	margin-bottom: 5px;
}
