@import '../../../../../../../../styles/colours.scss';

.tum-form-section-container {
	display: flex;
	flex-direction: column;
	row-gap: 15px;

	.section-header {
		display: flex;
		justify-content: space-between;

		.section-label {
			display: flex;
			column-gap: 10px;
			align-items: center;

			button {
				padding: 2px;

				svg {
					color: var(--primary);
				}
			}
		}
	}

	.section-form {
		display: grid;
		grid-template-columns: 2fr 5fr;
		column-gap: 15px;

		.tum-form-label {
			display: flex;
			align-items: center;
		}
	}

	.checkbox {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;

		.MuiFormControlLabel-root {
			margin-right: 0;
		}
	}

	.tum-form-field {
		position: relative;
		width: 100%; // Ensure the container takes the full width of its parent

		.MuiInputBase-root {
			border-radius: 0;
		}

		.MuiOutlinedInput-root {
			padding-right: 0;
		}

		.clear-icon {
			position: absolute;
			right: 8px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
		}

		.loading {
			position: absolute;
			right: 8px;
			top: 20%;
			transform: translateY(-50%);
		}

		ul {
			position: absolute;
			top: 100%;
			left: 0;
			width: 99.5%;
			max-height: 150px; // Optional: Limit the height of the dropdown for large lists
			overflow-y: auto; // Optional: Add scroll for large lists
			background-color: white;
			border: 1px solid #ccc;
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
			z-index: 10000; // Ensure the dropdown appears above other elements
			padding-inline-start: 0;
			margin: 0;
			border-radius: 0;

			li {
				padding: 10px 12px;
				cursor: pointer;
				white-space: normal; /* Allow text to wrap */
				word-break: break-word; /* Break words when necessary */
				overflow-wrap: break-word; /* Ensure long words break to fit within the container */

				&.disabled-item {
					cursor: not-allowed;
					color: #808080 !important;
				}

				&:hover,
				&.active {
					background-color: #f0f0f0;
				}

				svg {
					font-size: 25px;
					color: var(--primary);
				}

				.option-text {
					h5 {
						font-size: 15px;
					}

					.option-info {
						display: flex;
						gap: 6px;
						align-items: center;

						svg {
							font-size: 10px;
						}

						p {
							font-size: 13px;

							span {
								color: #d32f2f;
							}
						}
					}
				}
			}
		}
	}
}
