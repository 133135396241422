accept-compliance-container {
	padding: 50px 100px;
}

.accept-compliance-content {
	max-width: 850px;
	margin: 0 auto;
	background-color: #fafafa;
}

.accept-compliance-header h1 {
	display: flex;
	align-content: center;
	flex-wrap: wrap;
}

.accept-compliance-header img {
	width: 40px;
}

.accept-compliance-header {
	padding: 50px;
	border-bottom: 1px solid rgb(0, 117, 201);
	display: flex;
	justify-content: center;
	column-gap: 20px;
}

.accept-compliance-text {
	padding: 50px;
}

.accept-compliance-text p {
	margin-bottom: 5px;
}

.accept-compliance-text h2 {
	font-size: 22px;
	margin-bottom: 5px;
}

.accept-compliance-text h3 {
	font-size: 18px;
	margin-bottom: 20px;
}

.accept-compliance-text ol,
.accept-compliance-text ul {
	margin-bottom: 5px;
}

.accept-compliance-text ol li,
.accept-compliance-text ul li {
	list-style-position: inside;
	margin-bottom: 4px;
}

.accept-compliance-button {
	padding-bottom: 20px;
	text-align: center;
}

.accept-compliance-button .accept-complaince-loadingButton {
	color: #fafafa;
	background-color: rgb(0, 117, 201);
	margin-top: 10px;
}

.accept-compliance-button .top-margin {
	margin: 20px !important;
}

.accept-compliance-text a {
	text-decoration: none !important;
	color: rgb(0, 117, 201) !important;
}
