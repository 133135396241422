.pricebook-overview-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	column-gap: 20px;

	.pricebook-overview-filters {
		.pricebook-sku-heading {
			margin-top: 2rem;
			margin-bottom: 1.5rem;
			margin-left: 14px;
		}

		.MuiOutlinedInput-root {
			width: 300px !important;
		}
	}
}
