.order-status-display-container {
	display: flex;
	align-content: center;
	justify-content: center;
	padding: 20px;
	border: 1px solid #e0f3ff;

	.order-status-display-content {
		max-width: max-content;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		align-items: center;
		justify-content: center;

		.order-status-icon {
			padding: 25px;
			border-radius: 50%;
			border: 1px solid #0075c9;
			background-color: #e0f3ff;

			svg {
				font-size: 50px;
				color: #0075c9;
			}
		}

		.order-status-text {
			text-align: center;

			h4 {
				font-size: 18px;
			}
		}
	}
}
