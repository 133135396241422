.tn-address-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.tn-address-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		column-gap: 20px;

		h1 {
			font-size: 20px;
			color: #000;
			font-weight: 800;
		}

		.MuiSvgIcon-root {
			color: #0075c9 !important;
			font-size: 24px;
		}

		img {
			width: 50px !important;
		}

		.modal-close-button {
			margin-left: auto;
		}
	}

	.address-form-footer {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			padding: 15px 60px !important;
			background-color: #0075c9 !important;
			color: #fff !important;
			border-radius: 0 !important;
			margin-left: auto;
			margin-right: auto;

			&[disabled] {
				background-color: #66aee0 !important;
			}
		}
	}
}
