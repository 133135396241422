.address-form-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.address-form-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 50px;
		justify-content: space-between;
		margin: 30px 20px;

		.address-form-input {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 10px;
		}

		.address-form-input p {
			white-space: nowrap;
			flex-grow: 1;
			flex-shrink: 0;
			width: 150px;
			margin-right: 10px;
		}

		.input-field {
			flex-grow: 1;
			width: 100%;
			box-sizing: border-box;
		}
	}

	.address-form-footer {
		display: flex;
		justify-content: center;
		align-items: center;

		button {
			padding: 15px 60px !important;
			background-color: #0075c9 !important;
			color: #fff !important;
			border-radius: 0 !important;
			margin-left: auto;
			margin-right: auto;

			&[disabled] {
				background-color: #66aee0 !important;
			}
		}
	}
}
