.support-container {
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	.support-header {
		padding: 30px;
	}

	.support-header-img img {
		width: 100px;
	}

	h1 {
		font-size: 24px;
	}

	h3 {
		font-size: 20px;
	}

	.document-content {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 30px;
	}

	.support-tab-container .MuiTab-root {
		font-size: 15px !important;

		&.Mui-selected {
			font-weight: 700 !important;
		}
	}
}
