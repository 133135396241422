// Customer Overview

.customer-edit-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.customer-edit-content {
		flex: 1;

		.customer-edit-header {
			padding: 30px;
			border-bottom: 1px solid rgba(224, 224, 224, 1);

			h2 {
				font-size: 26px;
			}

			p {
				font-weight: 400;
				font-size: 14px;
			}
		}

		.customer-edit-options {
			padding: 30px;
		}
	}

	.customer-edit-buttons {
		padding: 30px;
		border-top: 1px solid rgba(224, 224, 224, 1);
	}
}

// Drawer
.icon-button .MuiSvgIcon-root {
	width: 20px !important;
	margin-right: 5px;
}

span.form-label,
.checkbox .MuiFormControlLabel-label {
	font-size: 14px !important;
}

.circle-icon-status-inactive {
	color: #727272 !important;
	align-items: flex-end;
	align-items: center;
}

.circle-icon-status-active {
	color: #26b087 !important;
	align-items: flex-end;
	align-items: center;
}

.add-trunks-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.add-trunks-content {
		flex: 1;

		.add-trunks-header {
			padding: 30px;
			border-bottom: 1px solid rgba(224, 224, 224, 1);

			h2 {
				font-size: 26px;
			}

			p {
				font-weight: 400;
				font-size: 14px;
			}
		}
	}
}

// add trunk group information
.add-trunk-group-information-container {
	display: flex;
	flex-direction: column;
	min-height: 80vh;
	margin: 25px;
	width: 400px;

	.add-trunk-group-information-header {
		margin-bottom: 15px;

		h4 {
			font-size: 22px;
		}
	}

	.add-trunk-group-information-content {
		p {
			font-weight: 400;
			font-size: 14px;
		}
	}
}

.table-menu {
	.MuiPaper-root {
		box-shadow: rgba(100, 100, 111, 0.2) 2px 3px 4px 2px !important;
	}
}

.grid-content {
	display: flex;
	justify-content: center;
	height: 150px;

	.grid-top-row {
		width: 100px;
		height: 50px;
		font-size: 15px;
	}

	.grid-bottom-row {
		height: 80px;
		font-size: 30px;
	}
}

.E911-card-content {
	padding: 20px;

	.E911-card-content-toggle {
		margin: 10px;

		.E911-text {
			margin-left: 16px;
			padding-bottom: 5px;
		}
	}

	.E911-card-content-info {
		display: flex;
		justify-content: flex-end;
	}
}
