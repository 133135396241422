.add-trunk-container {
	.add-trunk-content {
		display: flex;
		flex-direction: column;
		row-gap: 30px;

		.trunk-header-container {
			display: flex;
			align-items: center;
			justify-content: center;

			.trunk-header {
				text-align: center;
				padding: 10px;
				border: 1px solid rgba(0, 0, 0, 0.12);
				max-width: max-content;

				p {
					font-size: 20px;
				}
			}
		}

		h2 {
			font-size: 20px;
			text-transform: uppercase;
		}

		.add-trunk-customer-container {
			display: flex;
			align-items: center;
			justify-content: center;

			.add-trunk-customer {
				display: flex;
				flex-direction: row;
				column-gap: 15px;
				align-items: center;
				max-width: max-content;
				padding: 20px;
				border-radius: 10px;

				img {
					width: 60px;
				}

				h2 {
					display: flex;
					align-items: center;
					column-gap: 15px;
				}
			}
		}

		.trunk-region-delete {
			display: flex;
			justify-content: center;
			position: relative;

			svg.MuiSvgIcon-root {
				color: #db3d47 !important;
			}
		}

		.add-trunk-alert {
			display: flex;
			justify-content: center;

			.MuiPaper-root {
				max-width: max-content;
			}
		}

		.add-trunk-regions-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 30px;

			.add-trunk-region {
				padding: 20px 30px;
				border-radius: 10px;
				display: flex;
				flex-direction: column;
				row-gap: 20px;
				box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

				.add-trunk-region-header {
					display: flex;
					justify-content: space-between;
					column-gap: 30px;
					align-items: center;

					h3 {
						font-size: 18px;
						display: flex;
						align-items: center;
						column-gap: 10px;
					}
				}

				.add-trunk-input {
					display: flex;

					&.col {
						flex-direction: column;
						row-gap: 20px;
					}

					&.row {
						flex-direction: row;
						justify-content: space-between;
						align-items: center;
						column-gap: 30px;
					}

					h4 {
						display: flex;
						align-items: center;
						column-gap: 5px;
						font-size: 18px;

						& span {
							color: #d32f2f;
						}
					}

					p {
						display: flex;
						align-items: center;
						column-gap: 5px;

						& span {
							color: #d32f2f;
						}
					}

					.add-trunk-input-ip {
						display: grid;
						grid-template-columns: 1fr auto;
						column-gap: 10px;

						button {
							max-width: max-content;
						}
					}

					.MuiFormHelperText-root {
						margin: 5px 0 0 0;
					}
				}

				.trunk-ip-list-container {
					display: flex;
					flex-direction: column;
					row-gap: 10px;

					.trunk-ips {
						display: grid;
						grid-template-columns: auto auto auto auto;
						column-gap: 10px;
					}
				}

				.delete-trunk-region {
					text-align: center;
					margin-top: auto;

					button {
						background-color: #d32f2f;
					}
				}
			}
		}

		.add-trunk-buttons {
			display: flex;
			justify-content: center;
			column-gap: 30px;

			button.delete {
				background-color: #d32f2f;
			}
		}
	}
}
