.sre-ec-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.sre-ec-header {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		text-align: center;

		.header-icon {
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.icon {
				padding: 10px;

				img {
					width: 40px;
					filter: brightness(0) invert(1);
				}
			}
		}

		.header-text {
			display: flex;
			flex-direction: column;
			row-gap: 10px;

			h2 {
				font-size: 20px;
			}
		}
	}

	.sre-ec-e911 {
		padding: 5px 10px;
		background-color: #e9f7ff;
		text-align: center;
	}

	.sre-ec-btn {
		text-align: center;
	}
}

// Loading
.sre-ec-loading {
	padding: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	text-align: center;

	.sre-ec-loader {
		max-width: max-content;
		margin: auto;
	}
}

.modal:has(.sre-ec-loading) {
	overflow: hidden;
}
