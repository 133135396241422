.faq-container {
	padding: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.faq-content {
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		.MuiPaper-root {
			border-radius: 0;
			box-shadow: none;

			&.Mui-expanded {
				margin: 0 !important;
			}
		}

		.MuiButtonBase-root.MuiAccordionSummary-root.faq-title {
			background: rgba(224, 243, 255, 0.5) !important;
		}

		.faq-title .MuiAccordionSummary-content {
			font-weight: 600 !important;
			color: #000 !important;
		}

		.MuiAccordionDetails-root {
			padding: 15px;
		}

		.documentation-answer-container {
			display: flex;
			flex-direction: column;
			row-gap: 15px;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			span,
			button,
			b,
			ol li,
			ul li {
				color: #000 !important;
				font-size: 16px !important;
			}

			b {
				font-weight: 600 !important;
			}

			img {
				width: 900px;
				margin: 20px;
			}
		}
	}
}
