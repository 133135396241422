.sku-price-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.sku-price-header {
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		text-align: center;

		.header-icon {
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				padding: 10px;
				background-color: #0075c9;

				svg {
					font-size: 40px;
					color: #fff;
				}
			}
		}
	}

	.sku-price-order-details {
		display: flex;
		justify-content: center;
		align-items: center;

		.sku-text {
			max-width: max-content;
			padding: 10px;
			border: 1px solid rgba(0, 0, 0, 0.12);
		}
	}

	.sku-price-form {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	.sku-price-btn {
		text-align: center;
	}
}

// Loading
.sku-price-loading {
	padding: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
	text-align: center;

	.sku-price-loader {
		max-width: max-content;
		margin: auto;
	}
}

.modal:has(.sku-price-loading) {
	overflow: hidden;
}
