.side-menu-container {
  position: relative;
  z-index: 999;
}

.menu-item {
  column-gap: 10px;
  margin: 0 !important;
  padding: 11px 20px !important;

  img {
    width: 30px;
  }

  .menu-img.inv {
    filter: invert(1);
  }
}
