.oc-tenant-form-footer {
	display: flex;
	justify-content: center;
	align-items: center;

	button {
		padding: 15px 60px !important;
		background-color: #0075c9 !important;
		color: #fff !important;
		border-radius: 0 !important;
		margin-left: auto;
		margin-right: auto;

		&[disabled] {
			background-color: #66aee0 !important;
		}
	}
}
