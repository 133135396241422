// Main display
.order-display-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	.order-display-toolbar {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.filter-toolbar {
			display: grid;
			grid-template-columns: 1fr 0.01fr 1fr;
			column-gap: 30px;

			.section-heading {
				display: flex;
				flex-direction: row;
				column-gap: 10px;
				align-items: center;

				img {
					width: 30px;
				}

				h2 {
					font-size: 18px;
				}
			}
		}
	}

	.confirm-order svg {
		color: #0075c9;
	}
}

// Custom Footer
.order-footer {
	display: flex;

	.order-footer-content {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;
		padding: 10px 0;

		p {
			font-size: 14px !important;
		}

		.order-pagination {
			display: flex;
			flex-direction: row;
			column-gap: 5px;
			align-items: center;

			button {
				color: #0075c9;
				font-size: 14px !important;

				& svg {
					color: #0075c9;
				}

				&.disabled {
					color: #e0f3ff !important;
				}
			}
		}
	}
}

.order-options-menu .MuiPaper-root {
	box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 6px;

	.MuiButtonBase-root {
		padding: 10px 15px;
	}
}

.no-sku-display-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;

	.no-sku-display-content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		column-gap: 15px;
		padding: 20px;
		border: 1px solid rgba(0, 0, 0, 0.12);

		img {
			width: 45px;
		}
	}
}
