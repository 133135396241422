// Custom styling - Remove all reference to the main site
.page-content:has(.tdr-ssp-container) {
	padding: 0 !important;
}

#app:has(#gid234879) {
	// Remove header and sidemenu
	#header,
	#side-menu,
	#breadcrumbs {
		display: none;
	}

	// Style main box to remove flex
	main {
		display: block;
	}
}

// General styling
.tdr-ssp-container {
	.tdr-ssp-header {
		padding: 30px 50px;

		img {
			width: auto;
			max-height: 30px;
		}
	}

	.tdr-ssp-banner-container {
		position: relative;

		.tdr-ssp-banner {
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			background-image: url('../../assets/images/tdr-ssp/banner-bg.png');
			padding: 80px;
			position: relative;

			.overlay {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(
					0,
					117,
					201,
					0.7
				); /* Change the color and opacity here */
				pointer-events: none; /* Allow clicking through the overlay */
			}

			.text {
				position: absolute;
				top: 50%;
				left: 31%;
				transform: translate(-50%, -50%);
				text-align: center;
				font-size: 30px;
				color: #fff;
				text-transform: uppercase;
				font-weight: 700 !important;
			}
		}

		.text-box {
			position: absolute;
			bottom: -10%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			font-size: 16px;
			color: #0075c9;
			text-transform: uppercase;
			max-width: max-content;
			padding: 20px;
			border-radius: 10px;
			border: 1px solid #0075c9;
			background-color: #e0f3ff;
		}

		padding-bottom: 30px;
	}

	.tdr-ssp-sc-video {
		display: grid;
		grid-template-columns: 1fr 2fr;
		column-gap: 50px;

		.text {
			display: flex;
			align-items: center;

			h2 {
				font-size: 26px;
			}
		}
	}

	.tdr-ssp-h h4 {
		font-size: 24px !important;
	}

	.tdr-ssp-step-header {
		padding: 15px 20px;
		background: #0075c9;
		color: #fff;
		display: flex;
		flex-direction: row;
		column-gap: 15px;
		align-items: center;

		.step-number {
			border-radius: 50%;
			width: 24px;
			height: 24px;
			padding: 10px;
			background: #e0f3ff;
			color: #0075c9;
			text-align: center;
			border: 1px solid #000;
		}

		.step-text h3 {
			color: #fff;
		}
	}

	.tdr-ssp-content {
		display: flex;
		flex-direction: column;
		row-gap: 40px;
		padding: 50px 100px;

		h4 {
			font-size: 18px;
			font-weight: 500 !important;
		}

		h3 {
			font-size: 18px;
			font-weight: 500 !important;
			text-transform: uppercase;
			color: #0075c9;
		}

		.tdr-ssp-videos-container {
			position: relative;

			.tdr-ssp-videos {
				display: flex;
				flex-direction: column;
				row-gap: 30px;
			}

			img.lptp-img {
				max-width: 400px;
				position: absolute;
				right: 0;
				top: 30%;
			}
		}

		.tdr-ssp-img-text {
			display: grid;
			grid-template-columns: 1fr 2fr;
			column-gap: 50px;

			.img {
				text-align: center;
				display: flex;
				align-items: center;

				&.ctr {
					align-items: center;
					justify-content: center;
					max-width: 350px;
				}

				img {
					max-width: 350px;
					border-radius: 20px;
				}

				&.nbr img {
					border-radius: 0 !important;
				}
			}

			.text {
				display: flex;
				align-items: center;

				h4 {
					text-transform: uppercase;
				}

				p {
					font-size: 16px;
					font-weight: 300 !important;
				}
			}

			.img-text {
				display: flex;
				flex-direction: column;

				&.opt {
					text-align: center;
					max-width: 350px;
					align-items: center;
					justify-content: center;

					h3 {
						font-size: 30px;
						text-align: center;
						color: #000;
						margin-bottom: 10px;
					}
				}

				&.ctr {
					align-items: center;
					justify-content: center;
					max-width: 350px;
				}

				h4 {
					text-transform: uppercase;
					margin-bottom: 20px;
					text-align: center;
				}

				img {
					max-width: 200px;
				}
			}

			.multi-text {
				display: flex;
				flex-direction: column;
				row-gap: 20px;

				h4 {
					text-transform: uppercase;
				}

				p {
					font-size: 16px;
					font-weight: 300 !important;
				}

				&.m {
					justify-content: center;
				}
			}
		}
	}
}
